import {useRouter} from 'next/router';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_E} from '@riiid/design-system';

import {Button, SinglePageLayout, SystemErrorLayout} from '@santa-web/service-ui';
import {use100vh} from '@app/hooks/use100vh';

const Page404 = () => {
  const router = useRouter();
  const viewportHeight = use100vh();
  const {t} = useTranslation();

  const handleErrorButtonClick = useCallback(async () => {
    router.replace('/');
  }, [router]);

  const button = (
    <Button variant="box-line" onClick={handleErrorButtonClick}>
      {t('error_page_404_error_button')}
    </Button>
  );

  return (
    <div
      css={css`
        width: 100%;
        height: ${viewportHeight};
        background: ${COLOR_SANTA_E};
      `}>
      <SinglePageLayout isError>
        <SystemErrorLayout
          title={t('error_page_404_error_title')}
          icon="warning"
          description={t('error_page_404_error_description')}
          button={button}
        />
      </SinglePageLayout>
    </div>
  );
};

export default Page404;
